import React, { useEffect, useState } from 'react';
import './ProductList.css';  // Assuming you'll update the CSS accordingly

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://api.budtechenterprise.co.ke/api/products/');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched products:', data);
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="product-list-container">
      <h2>Products</h2>
      <div className="product-grid">
        {products.map(product => {
          const imageUrl = product.image ? `http://api.budtechenterprise.co.ke${product.image}` : '';

          return (
            <div key={product.id} className="product-card">
              <img
                src={imageUrl}
                alt={product.name}
                className="product-image"
                onError={() => console.error(`Failed to load image: ${imageUrl}`)}
              />
              <h3 className="product-name">{product.name}</h3>
              <p className="product-description">{product.description}</p>
              <p className="product-price">Kshs. {Number(product.price).toFixed(2)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductList;
