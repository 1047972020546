import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ProductList from './pages/ProductList';
import ProductDetails from './pages/ProductDetails'; // Import ProductDetails component
import Cart from './pages/Cart';
import SignUp from './components/SignUp';
import Login from './components/Login';
import CategoryPage from './pages/CategoryPage';
import WhatsAppButton from './components/WhatsAppButton'; // Import the WhatsAppButton

function App() {
  return (
    <div>
      <Navbar />
      <WhatsAppButton /> {/* Add the WhatsAppButton here */}
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<ProductDetails />} /> {/* Add route for ProductDetails */}
          <Route path="/cart/" element={<Cart />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/category/:categoryName" element={<CategoryPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
