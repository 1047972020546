import React from 'react';
import './WhatsAppButton.css'; // Ensure to import the CSS file

const WhatsAppButton = () => {
    return (
        <div className="navbar-whatsapp">
            <a 
                href="https://wa.me/254724118790?text=Hello,%20I%27m%20interested%20in%20your%20IT%20products%20such%20as%20laptops,%20computer%20accessories,%20Windows,%20Office,%20antivirus,%20and%20more.%20Can%20you%20provide%20more%20details?" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="whatsapp-link"
            >
                Chat with us on WhatsApp
                <span className="phone-number">
                    <span className="digit">0</span>
                    <span className="digit">7</span>
                    <span className="digit">2</span>
                    <span className="digit">4</span>
                    <span className="digit">1</span>
                    <span className="digit">1</span>
                    <span className="digit">8</span>
                    <span className="digit">7</span>
                    <span className="digit">9</span>
                    <span className="digit">0</span>
                </span>
            </a>
        </div>
    );
}

export default WhatsAppButton;
