import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './CategoryPage.css'; // Import CSS for styling

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const response = await fetch(`https://api.budtechenterprise.co.ke/api/products/category/${categoryName}/`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        
        // Filter out "Latest" and "Most Selling" categories
        const filteredData = data.filter(product => 
          product.category.name !== 'Latest' && product.category.name !== 'Most Selling'
        );

        setProducts(filteredData);
      } catch (error) {
        console.error('Error fetching products by category:', error);
        setError('Failed to load product data. Please try again later.');
      }
    };

    fetchProductsByCategory();
  }, [categoryName]);

  return (
    <div className="category-page">
      <header className="category-header">
        <h1>{categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}</h1>
      </header>

      {error && <p className="error-message">{error}</p>}

      <div className="product-list-container">
        <div className="product-grid">
          {products.length > 0 ? (
            products.map(product => {
              const imageUrl = product.image ? `https://api.budtechenterprise.co.ke${product.image}` : 'placeholder-image.jpg';

              return (
                <div key={product.id} className="product-item">
                  <Link to={`/products/${product.id}`}>
                    <div className="product-image-wrapper">
                      <img src={imageUrl} alt={product.name} className="product-image" />
                    </div>
                    <div className="product-details">
                      <h3 className="product-name">{product.name}</h3>
                      <p className="product-price">Kshs. {new Intl.NumberFormat().format(product.price)}</p>
                      <p className="product-description">{product.description}</p>
                      <Link to={`/products/${product.id}`} className="view-details-button">View Details</Link>
                    </div>
                  </Link>
                </div>
              );
            })
          ) : (
            <p>Out of stock</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
