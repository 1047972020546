import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../components/Carousel';
import './HomePage.css';

function HomePage() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [overflowingCategories, setOverflowingCategories] = useState({});
  const [visibleArrows, setVisibleArrows] = useState({});
  const productRefs = useRef({}); // Store refs for each product category list

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.budtechenterprise.co.ke/api/products/');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load product data. Please try again later.'); // Update the error message
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Check for each category if it overflows
    Object.keys(productRefs.current).forEach((category) => {
      const productList = productRefs.current[category];
      if (productList) {
        const isOverflowing = productList.scrollWidth > productList.clientWidth;
        setOverflowingCategories(prev => ({
          ...prev,
          [category]: isOverflowing
        }));
        // Update visibility of arrows based on initial scroll position
        setVisibleArrows(prev => ({
          ...prev,
          [category]: {
            left: productList.scrollLeft > 0,
            right: productList.scrollWidth > productList.clientWidth && productList.scrollLeft < (productList.scrollWidth - productList.clientWidth)
          }
        }));
      }
    });
  }, [products]);

  const handleScroll = (category) => {
    const productList = productRefs.current[category];
    if (productList) {
      setVisibleArrows(prev => ({
        ...prev,
        [category]: {
          left: productList.scrollLeft > 0,
          right: productList.scrollWidth > productList.clientWidth && productList.scrollLeft < (productList.scrollWidth - productList.clientWidth)
        }
      }));
    }
  };

  const scrollLeft = (category) => {
    const productList = productRefs.current[category];
    if (productList) {
      productList.scrollBy({ left: -300, behavior: 'smooth' });
      handleScroll(category); // Update arrow visibility after scrolling
    }
  };

  const scrollRight = (category) => {
    const productList = productRefs.current[category];
    if (productList) {
      productList.scrollBy({ left: 300, behavior: 'smooth' });
      handleScroll(category); // Update arrow visibility after scrolling
    }
  };

  const groupedProducts = products.reduce((acc, product) => {
    const category = product.category ? product.category.name : 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(product);
    return acc;
  }, {});

  const renderProductList = (products, category) => (
    <div
      className="product-list"
      ref={el => productRefs.current[category] = el}
      onScroll={() => handleScroll(category)} // Handle scroll events
    >
      {products.length > 0 ? (
        products.map(product => {
          const imageUrl = product.image ? `https://api.budtechenterprise.co.ke${product.image}` : 'placeholder-image.jpg';
          return (
            <div key={product.id} className="product-item">
              <div className="product-image-wrapper">
                <img
                  src={imageUrl}
                  alt={product.name}
                  className="product-image"
                  onError={(e) => { e.target.src = 'placeholder-image.jpg'; }} // Fallback image
                />
              </div>
              <h3 className="product-name">{product.name}</h3>
              <p className="product-description">{product.description}</p>
              <p className="product-price">Kshs. {new Intl.NumberFormat().format(product.price)}</p>
              <p className="product-was-price">Kshs.<span className="strikethrough"> {new Intl.NumberFormat().format(product.was_price)}</span></p>
              <Link to={`/products/${product.id}`} className="view-details-button">View Details</Link>
            </div>
          );
        })
      ) : (
        <p>No products available</p>
      )}
    </div>
  );

  return (
    <div className="home-page">

      {/* Carousel Section */}
      <section className="carousel-section">
        <Carousel />
      </section>

      {/* Display error message if any */}
      {error && <p className="error-message">{error}</p>}

      {/* Display Products by Category */}
      {Object.keys(groupedProducts).length > 0 ? (
        Object.keys(groupedProducts).map(category => (
          <section key={category} className={`product-category ${category.toLowerCase().replace(/\s+/g, '-')}`}>
            <h2>{category}</h2>

            {/* Conditionally render scroll buttons only if the category overflows */}
            {overflowingCategories[category] && visibleArrows[category]?.left && (
              <button className="scroll-button left" onClick={() => scrollLeft(category)}>
                &#8592; {/* Left arrow */}
              </button>
            )}

            {/* Product List */}
            {renderProductList(groupedProducts[category], category)}

            {overflowingCategories[category] && visibleArrows[category]?.right && (
              <button className="scroll-button right" onClick={() => scrollRight(category)}>
                &#8594; {/* Right arrow */}
              </button>
            )}
          </section>
        ))
      ) : (
        <p>No product categories available.</p> // Display if no categories found
      )}
    </div>
  );
}

export default HomePage;
