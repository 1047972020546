import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import './ProductDetails.css'; // Ensure this CSS includes the updated styles

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedLoading, setRelatedLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const imagesSliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [quantity, setQuantity] = useState(1);

  // Fetch product details
  const fetchProduct = async () => {
    try {
      const response = await fetch(`https://api.budtechenterprise.co.ke/api/products/${id}/`);
      if (!response.ok) throw new Error('Failed to fetch product');
      const data = await response.json();
      setProduct(data);

      // Calculate total slides
      const additionalImages = data.additional_images || [];
      setTotalSlides(1 + additionalImages.length);
    } catch (error) {
      console.error('Error fetching product:', error);
      setError('Failed to load product details');
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [id]);

  // Fetch related products
  useEffect(() => {
    if (product?.category?.id) {
      setRelatedLoading(true);

      const fetchRelatedProducts = async () => {
        try {
          const relatedResponse = await fetch(`https://api.budtechenterprise.co.ke/api/products/?category=${product.category.id}`);
          if (!relatedResponse.ok) throw new Error('Failed to fetch related products');
          const relatedData = await relatedResponse.json();

          const otherCategoriesResponse = await fetch(`https://api.budtechenterprise.co.ke/api/products/?exclude_category=${product.category.id}`);
          if (!otherCategoriesResponse.ok) throw new Error('Failed to fetch other category products');
          const otherCategoriesData = await otherCategoriesResponse.json();

          const combinedProducts = Array.from(new Set([...relatedData, ...otherCategoriesData].map(p => p.id)))
            .map(id => [...relatedData, ...otherCategoriesData].find(p => p.id === id));

          setRelatedProducts(combinedProducts.filter(p => p.id !== product.id));
        } catch (error) {
          console.error('Error fetching related products:', error);
          setError('Failed to load related products');
        } finally {
          setRelatedLoading(false);
        }
      };

      fetchRelatedProducts();
    }
  }, [product?.category?.id, product?.id]);

  // Handle image slider change
  const changeSlide = useCallback((direction) => {
    if (imagesSliderRef.current && totalSlides > 0) {
      const newIndex = (currentIndex + direction + totalSlides) % totalSlides;
      setCurrentIndex(newIndex);
      imagesSliderRef.current.style.transition = 'none'; // Disable transition for instant change
      imagesSliderRef.current.style.transform = `translateX(-${newIndex * 100}%)`;
      
      // Re-enable transition and handle reset
      setTimeout(() => {
        imagesSliderRef.current.style.transition = 'transform 0.5s ease';
      }, 50); // Ensure the transition is re-enabled after the change
    }
  }, [currentIndex, totalSlides]);

  // Automatic sliding for images
  useEffect(() => {
    if (imagesSliderRef.current && totalSlides > 0) {
      // Clone the first and last slides for smooth transition
      const firstSlide = imagesSliderRef.current.children[0];
      const lastSlide = imagesSliderRef.current.children[totalSlides - 1];
      
      // Append clones
      imagesSliderRef.current.appendChild(firstSlide.cloneNode(true));
      imagesSliderRef.current.insertBefore(lastSlide.cloneNode(true), imagesSliderRef.current.firstChild);
      
      // Set initial position
      setCurrentIndex(1); // Start from the second slide (0-indexed)
      imagesSliderRef.current.style.transform = `translateX(-${100}%)`;
    }
  }, [totalSlides]);

  // Handle adding item to cart
  const handleAddToCart = async () => {
    try {
      const productId = parseInt(id, 10);
      const data = {
        product: productId,
        quantity: quantity,
      };

      const response = await fetch('https://api.budtechenterprise.co.ke/api/orders/cart/add/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to add item to cart');
      }

      // Fetch updated product information
      await fetchProduct();
      alert('Item added to cart!');
      fetchCartItems();
    } catch (error) {
      console.error('Error adding item to cart:', error);
      setError('Failed to add item to cart');
    }
  };

  // Fetch cart items
  const fetchCartItems = async () => {
    try {
      const response = await fetch('https://api.budtechenterprise.co.ke/api/orders/list/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to fetch cart items');
      const data = await response.json();
      setCartItems(data.items);
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setError('Failed to load cart items');
    }
  };

  // Convert comma-separated descriptions to list items
  const descriptionList = product?.description
    ? product.description.split(',').map((desc, index) => <li key={index}>{desc.trim()}</li>)
    : null;

  // Handle image click to show modal
  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setModalImage('');
  };

  // Update quantity
  const increaseQuantity = () => {
    setQuantity(prev => {
      if (product && prev < product.stock) {
        return prev + 1;
      }
      return prev;
    });
  };

  const decreaseQuantity = () => {
    setQuantity(prev => (prev > 1 ? prev - 1 : 1)); // Ensure quantity doesn't go below 1
  };

  if (error) return <div className="error">{error}</div>;
  if (!product) return <div className="loading">Loading...</div>;

  return (
    <div className="product-detail">
      <div className="image-info-container">
        <div className="images-slider-container">
          <div className="images-slider" ref={imagesSliderRef}>
            {product.image && (
              <div className="image-slide" onClick={() => handleImageClick(`https://api.budtechenterprise.co.ke${product.image}`)}>
                <img src={`https://api.budtechenterprise.co.ke${product.image}`} alt={product.name} />
              </div>
            )}
            {(product.additional_images || []).map((image) => (
              <div
                key={image.id}
                className="image-slide"
                onClick={() => handleImageClick(
                  image.additional_image && image.additional_image.startsWith('/')
                    ? `https://api.budtechenterprise.co.ke${image.additional_image}`
                    : image.additional_image || ''
                )}
              >
                <img
                  src={image.additional_image && image.additional_image.startsWith('/')
                    ? `https://api.budtechenterprise.co.ke${image.additional_image}`
                    : image.additional_image || ''}
                  alt={`Additional view of ${product.name}`}
                />
              </div>
            ))}
          </div>
          <button className="slider-arrow left" onClick={() => changeSlide(-1)}>‹</button>
          <button className="slider-arrow right" onClick={() => changeSlide(1)}>›</button>
        </div>
        <div className="product-info">
          <h1 className="product-name">{product.name}</h1>
          <div className="product-description">
            <ul>{descriptionList}</ul>
          </div>
          <div className="product-details">
            <div className="price-container">
              <div className="product-price">Kshs.<span> {product.price}</span></div>
              <div className="product-was-price">Kshs.<span className="strikethrough"> {product.was_price}</span></div>
            </div>
            <div className="product-warranty">Warranty Period: <span>{product.warranty_period}</span></div>
            <div className="product-stock">
              {product.stock > 0 ? (
                <>Stock: <span>{product.stock}</span></>
              ) : (
                <span className="out-of-stock">Out of Stock</span>
              )}
            </div>
          </div>

          {product.stock > 0 && (
            <div className="quantity-container">
              <button className="quantity-button" onClick={decreaseQuantity}>-</button>
              <span className="quantity-display">{quantity}</span>
              <button className="quantity-button" onClick={increaseQuantity}>+</button>
            </div>
          )}

          <button 
            className="add-to-cart-button" 
            onClick={handleAddToCart}
            disabled={product.stock === 0 || quantity > product.stock} // Disable the button if out of stock or quantity exceeds stock
          >
            {product.stock > 0 ? 'Make an Order' : 'Out of Stock'}
          </button>
        </div>
      </div>

      {relatedLoading ? (
        <div>Loading related products...</div>
      ) : (
        relatedProducts.length > 0 && (
          <div className="related-products">
            <h2>Related Products</h2>
            <div className="related-products-slider">
              {relatedProducts.map((relatedProduct) => (
                <div key={relatedProduct.id} className="related-product-card">
                  <Link to={`/products/${relatedProduct.id}`}>
                    <img
                      src={`https://api.budtechenterprise.co.ke${relatedProduct.image}`}
                      alt={relatedProduct.name}
                    />
                    <div className="related-product-name">{relatedProduct.name}</div>
                    <div className="related-product-price">Kshs. {relatedProduct.price}</div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )
      )}

      {/* Modal for Enlarged Image */}
      {showModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={modalImage} alt="Enlarged" />
          </div>
        </div>
      )}

      {/* Cart Items Display */}
      <div className="cart-items">
        {cartItems.length > 0 ? (
          <ul>
            {cartItems.map(item => (
              <li key={item.id}>
                {item.product.name} - Quantity: {item.quantity}
              </li>
            ))}
          </ul>
        ) : (
          <p>No items in the cart.</p>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
