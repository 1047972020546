import React from 'react';
import './Footer.css';  // Import CSS for styling
import logoImage from '../assets/blogo.png';  // Adjust the path to your logo image
import backgroundImage from '../assets/footer-bg.jpg';  // Import background image

const Footer = () => (
  <footer className="footer" >
    <div className="footer-container">
      <div className="footer-column">
        <h3>About Us</h3>
        <a href="/" className="navbar-logo">
          <img src={logoImage} alt="Logo" className="logo-image" />
        </a>
        <p>Our goal is to ensure that our customers get the best tech products and services to meet their needs.</p>
      </div>
      <div className="footer-column">
        <h3>Contact</h3>
        <ul className="contact-info">
          <li>Email: <a href="mailto:info@budtechenterprises.com">info@budtechenterprises.com</a></li>
          <li>WhatsApp: <a href="https://wa.me/254724118790?text=Hello,%20I%27m%20interested%20in%20your%20IT%20products%20such%20as%20laptops,%20computer%20accessories,%20Windows,%20Office,%20antivirus,%20and%20more.%20Can%20you%20provide%20more%20details?" 
                target="_blank" 
                rel="noopener noreferrer">+254 724 118 790</a></li>
          <li>Phone: <a href="tel:+254724118790">+254 724 118 790</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Location</h3>
        <div className="map-container">
          {/* Google Map embed (replace with your specific location link) */}
          <iframe
            title="Budtech Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3154.99489822598!2d144.95373631531752!3d-37.8172099797517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43bf1b3f3b%3A0xa2f11e3b29c8195!2sBudtech%20Enterprises!5e0!3m2!1sen!2ske!4v1605213622259!5m2!1sen!2ske"
            width="300"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <p>&copy; {new Date().getFullYear()} Budtech Enterprises. All rights reserved.</p>
      <ul className="footer-menu">
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/terms">Terms of Service</a></li>
      </ul>
    </div>
  </footer>
);

export default Footer;
