import React, { useState, useEffect, useCallback } from 'react';
import './Carousel.css';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start from 1 to skip the duplicate last slide
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const slideInterval = 5000; // Slide interval in milliseconds
  const [isTransitioning, setIsTransitioning] = useState(false); // Handle smooth transition

  useEffect(() => {
    // Fetch products from both Latest and Most Selling categories
    const fetchProducts = async () => {
      try {
        const latestResponse = await fetch('https://api.budtechenterprise.co.ke/api/products/category/Latest/');
        const mostSellingResponse = await fetch('https://api.budtechenterprise.co.ke/api/products/category/Most%20Selling/');
        
        if (!latestResponse.ok || !mostSellingResponse.ok) {
          throw new Error('Failed to fetch products');
        }

        const latestData = await latestResponse.json();
        const mostSellingData = await mostSellingResponse.json();

        // Combine both Latest and Most Selling products
        const combinedProducts = [...latestData, ...mostSellingData];
        setProducts(combinedProducts);
      } catch (err) {
        console.error(err);
        setError('Unable to fetch products');
      }
    };

    fetchProducts();
  }, []);

  const itemsToShow = 1; // Number of items to show in the carousel
  const slideWidth = 100 / itemsToShow;

  // Go to the next slide
  const nextSlide = useCallback(() => {
    if (isTransitioning) return;

    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, [isTransitioning]);

  // Handle infinite loop effect
  useEffect(() => {
    if (products.length > 0) {
      const timeout = setTimeout(() => {
        setIsTransitioning(false);

        if (currentIndex === products.length + 1) {
          setCurrentIndex(1); // Reset to the first product (skip the duplicated first)
        } else if (currentIndex === 0) {
          setCurrentIndex(products.length); // Reset to the last product (skip the duplicated last)
        }
      }, 500); // Duration of the transition effect

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, products.length]);

  useEffect(() => {
    const intervalId = setInterval(nextSlide, slideInterval);
    return () => clearInterval(intervalId);
  }, [nextSlide, slideInterval]);

  const prevSlide = () => {
    if (isTransitioning) return;

    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-container">
      {error ? (
        <p>{error}</p>
      ) : (
        <div className="three-columns">
          <div className="column left"></div>
          <div className="column middle">
            <div className="carousel">
              <button className="carousel-button prev" onClick={prevSlide}>
                &lt;
              </button>
              <div className="carousel-inner">
                <div
                  className="carousel-slides"
                  style={{ transform: `translateX(-${currentIndex * slideWidth}%)`, transition: isTransitioning ? 'transform 0.5s ease' : 'none' }}
                >
                  {/* Duplicate last slide at the beginning */}
                  {products.length > 0 && (
                    <div className="carousel-slide">
                      <img
                        src={`https://api.budtechenterprise.co.ke${products[products.length - 1].image}`}
                        alt={products[products.length - 1].name}
                        className="carousel-image"
                      />
                      <div className="carousel-product-info">
                        <h3>{products[products.length - 1].name}</h3>
                        <p>Kshs. {Number(products[products.length - 1].price).toFixed(2)}</p>
                      </div>
                    </div>
                  )}

                  {products.map((product, index) => (
                    <div key={product.id} className="carousel-slide">
                      <img
                        src={`https://api.budtechenterprise.co.ke${product.image}`}
                        alt={product.name}
                        className="carousel-image"
                      />
                      <div className="carousel-product-info">
                        <h3>{product.name}</h3>
                        <p>Kshs. {Number(product.price).toFixed(2)}</p>
                      </div>
                    </div>
                  ))}

                  {/* Duplicate first slide at the end */}
                  {products.length > 0 && (
                    <div className="carousel-slide">
                      <img
                        src={`https://api.budtechenterprise.co.ke${products[0].image}`}
                        alt={products[0].name}
                        className="carousel-image"
                      />
                      <div className="carousel-product-info">
                        <h3>{products[0].name}</h3>
                        <p>Kshs. {Number(products[0].price).toFixed(2)}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <button className="carousel-button next" onClick={nextSlide}>
                &gt;
              </button>
              <div className="carousel-dots">
                {products.map((_, index) => (
                  <button
                    key={index}
                    className={`carousel-dot ${index === currentIndex - 1 ? 'active' : ''}`}
                    onClick={() => goToSlide(index + 1)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="column right"></div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
